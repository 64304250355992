'use client'

import { createContext, useContext, useState } from "react"
import { useReducer } from 'react';

const CompareProviderContext = createContext<any>(undefined)

type Product = { id: string }

export function useCompareContext() {
    return useContext(CompareProviderContext)
}

function reducer(state: any, action: any) {
    var id = action.id

    switch (action.type) {
        case 'add': {
            return { ...state, [id]: { id } }
        }
        case 'remove': {
            delete state[id]
            return Object.assign({}, state)
        }
    }

    return state
}


const CompareProvider = ({ children, products: propProducts }: { children: React.ReactNode, products: { [key: string]: Product } }) => {
    const [state, dispatch] = useReducer(reducer, propProducts);

    let [show, setShow] = useState<boolean>(false)
    const toggleShow = () => setShow(show => !show)

    return (
        <CompareProviderContext.Provider value={{
            show,
            setShow,
            toggleShow,

            products: state,
            dispatch,

            addProduct: (id: string) => dispatch({ type: 'add', id }),
            removeProduct: (id: string) => dispatch({ type: 'remove', id }),
        }}>
            {children}
        </CompareProviderContext.Provider>
    )
}

export default CompareProvider
