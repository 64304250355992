'use client'

import type { ButtonProps } from '.';

import { type FC, type PropsWithChildren } from 'react';
import BsButton from 'react-bootstrap/Button';
import classNames from 'classnames';
import Spinner from 'react-bootstrap/Spinner';


const Button: FC<ButtonProps> = ({
  children,
  icon,
  isIconRight,
  iconWidth,
  iconVariant,
  className,
  centered = false,
  loading = false,
  shadow = false,
  href,
  external = false,
  ...props
}: PropsWithChildren<ButtonProps>) => {

  return (
    <BsButton
      {...props}

      className={classNames(`d-inline-flex align-items-center  fw-semibold`, [
        { [`${className}`]: className },
        { 'button-link text-decoration-none text-dark': 'a' === props.as },
        { 'shadow': shadow },
        { 'justify-content-center': centered },
        { [`icon icon--${icon}`]: icon && false === loading },
        { [`icon--${iconVariant}`]: iconVariant },
        { [`icon--size__${iconWidth}`]: iconWidth }
      ])}
    >
      {children}
      {true === loading && <Spinner size={'sm'} />}
    </BsButton>
  );
};

export default Button;
