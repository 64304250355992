'use client'

import { useFormStatus } from 'react-dom'

import Button, { type ButtonProps } from "@components/atoms/button"

const SubmitButton = ({ loading, ...props }: ButtonProps) => {
    const { pending } = useFormStatus()
    return (
        <Button aria-disabled={loading || pending} loading={loading || pending} disabled={loading || pending} {...props} />
    )
}

export default SubmitButton
