export default function formatPrice({
    value,
    minValue,
    taxRate = 0,
    purchaseUnit = 1,
    referenceUnit = 1,
    locale = 'de-DE',
    currencyId = 'EUR'
}: {
    value: number;
    taxRate?: number
    purchaseUnit?: number;
    referenceUnit?: number;
    locale?: string;
    currencyId?: string;
    minValue?: number;
}) {
    let val = (isNaN(value) ? 0 : ((value / purchaseUnit)) * (referenceUnit ?? 1)) * (1 + (taxRate / 100))

    if (minValue) {
        val = Math.max(val, minValue)
    }

    return Intl.NumberFormat(locale, { style: 'currency', currency: currencyId, }).format(val);
}
