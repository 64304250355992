'use client'

import type { TaxProps } from "."
import { useSession } from "next-auth/react"

const Tax = ({ taxId, className, longText = false }: TaxProps) => {
    const { data: session } = useSession()

    const tax = session?.user?.taxRules?.find(({ id }: { id: string }) => id === taxId)

    return (
        <span className={`${className ?? 'w-100  d-block  fs-9  fw-semibold'}`}>{longText && 'Alle Preise '}{true === session?.user?.group?.displayGross ? `inkl.${tax ? ` ${tax.taxRate} %` : ''} MwSt.` : `zzgl. MwSt.`}</span>
    )
}

export default Tax
