'use client'

import type { MultiCarouselProps } from '.';

import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Virtual, Autoplay } from 'swiper/modules';
import cn from 'classnames';

const MultiCarousel = ({
  children,
  navigationVariant = 'dark',
  navigationOutside = false,
  navigationSize = 'large',
  hasNavigation = true,
  hasPagination = false,
  paginationLeft = false,
  paginationOutside = true,
  autoplay = false,
  controller,
  ssr = false,
  slideTo,
  className,
  bulletColor = 'white',
  minHeight,
  style,
  slidesPerView,
  spaceBetween,
  breakpoints = {
    0: { slidesPerView: 1 },
    420: { slidesPerView: 1 },
    576: { slidesPerView: 2 },
    1280: { slidesPerView: 4 }
  },
  ...props
}: MultiCarouselProps) => {

  const [swiper, setSwiper] = useState<any>(null);

  useEffect(() => {
    if (null === swiper || undefined === slideTo) return
    swiper.slideTo(slideTo)
  }, [slideTo, swiper])

  return (
    <div className={cn('swiper-container position-relative overflow-hidden z-1', {
      [`${className}`]: className,
      [`swiper-navigation-size-${navigationSize}`]: navigationSize,
      '-mx-3': true === navigationOutside,
      'swiper-pagination-align-left': paginationLeft,
      'swiper-pagination-align-inside': !paginationOutside
    })}>
      <Swiper
        {...props}
        onSwiper={setSwiper}
        className={cn(null, [{ 'swiper-navigation-outside': navigationOutside }, { [`${navigationVariant}`]: hasNavigation }, { 'h-100': !minHeight }])}
        style={{ ...style, ...(minHeight && { minHeight }) }}
        modules={[Virtual, ...(autoplay ? [Autoplay] : []), ...(hasPagination ? [Pagination] : []), ...(hasNavigation ? [Navigation] : [])]}
        navigation={hasNavigation}
        pagination={hasPagination && {
          clickable: true,
          bulletClass: cn('swiper-pagination-bullet rounded-0', [{ 'swiper-pagination-bullet__secondary': 'secondary' === bulletColor }]),
          bulletActiveClass: cn('swiper-pagination-bullet-active', [{ 'swiper-pagination-bullet-active__secondary': 'secondary' === bulletColor }])
        }}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        breakpoints={breakpoints}
        {...(false !== autoplay && autoplay instanceof Object) && {
          loop: true,
          autoplay: {
            ...autoplay,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
            waitForTransition: true
          }
        }}
      >{Array.isArray(children) ? (
        children.map((child: any, index: number) => {
          if (child.type === SwiperSlide) {
            return child
          }
          return (
            <SwiperSlide key={child.key ?? index} className={props.slideClass}>
              {child}
            </SwiperSlide>
          )
        })
      ) : (
        children
      )}
        {controller}
      </Swiper>
    </div>
  );
};

export default MultiCarousel;
