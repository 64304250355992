'use client'

import { useSession } from "next-auth/react";
import { createContext, useContext, useEffect } from "react"
import { useReducer } from 'react';
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import { handleAddProductToWishlist, handleRemoveProductFromWishlist } from "@app/actions";
import getWishlist from "@api/get-wishlist";

// import facebookSendEvent from "@/helpers/facebook-send-event";
// import googleSendEvent from "@/helpers/google-send-event";

const WishlistProviderContext = createContext<any>(undefined)

type Product = { id: string }

export function useWishlistContext() {
    return useContext(WishlistProviderContext)
}

function reducer(state: any, action: any) {
    var id = action.id
    var items = action.items

    switch (action.type) {
        case 'add': {
            return items ?? { ...state, [id]: { id } }
        }
        case 'remove': {
            delete state[id]
            return Object.assign({}, state)
        }
    }

    return state
}

const getProductsFromWishlistCookie = () => {
    const getWishlistString = Cookies.get('swWishlist');
    const getWishlistStringArray: { id: string }[] = getWishlistString ? JSON.parse(getWishlistString) : [];

    let state: any = {};
    getWishlistStringArray.forEach(({ id }) => state = ({ ...state, [id]: { id } }))

    return state
}

const addProductToWishlistCookie = (id: string) => {
    const getWishlistString = Cookies.get('swWishlist');
    const getWishlistStringArray: { id: string }[] = getWishlistString ? JSON.parse(getWishlistString) : [];
    const index = getWishlistStringArray.findIndex((p: any) => p.id === id);

    if (index > -1) {
        getWishlistStringArray.splice(index, 1);
    } else {
        getWishlistStringArray.splice(0, 0, { id });
    }

    Cookies.set('swWishlist', JSON.stringify(getWishlistStringArray), { sameSite: 'strict' });

    return getWishlistStringArray.length
}

const removeProductFromWishlistCookie = (id: string) => {
    const getWishlistString = Cookies.get('swWishlist');
    const getWishlistStringArray: { id: string }[] = getWishlistString ? JSON.parse(getWishlistString) : [];
    const index = getWishlistStringArray.findIndex((p: any) => p.id === id);

    if (index > -1) {
        getWishlistStringArray.splice(index, 1);
    }

    Cookies.set('swWishlist', JSON.stringify(getWishlistStringArray), { sameSite: 'strict' });
}

const WishlistProvider = ({ children, products: propProducts }: { children: React.ReactNode, products: { [key: string]: Product } }) => {
    const { data: session } = useSession()
    const [state, dispatch] = useReducer(reducer, propProducts);

    useEffect(() => {
        if (!session) return

        if (true === session.user?.anonym && 0 === Object.keys(state).length) {
            dispatch({ type: 'add', items: getProductsFromWishlistCookie() })
        } else if (false === session.user?.anonym && 0 === Object.keys(state).length) {
            const fetch = async () => {
                await getWishlist().then(wishlist => {
                    let state: any = {};
                    wishlist.forEach(({ id }: { id: string }) => state = ({ ...state, [id]: { id } }))
                    dispatch({ type: 'add', items: state })
                })
            }
            fetch()
        }
    }, [session])

    const addProductToWishlist = (id: string) => {
        if (true === session?.user?.anonym) {
            const count = addProductToWishlistCookie(id)
            toast.success(`Sie können die Merkliste nur nutzen, wenn Sie angemeldet sind. Die Artikel werden zwischengespeichert und hinzugefügt, sobald Sie sich angemeldet haben. Sie haben ${count} Artikel zwischengespeichert.`);
            dispatch({ type: 'add', id })
        } else if (false === session?.user?.anonym) {
            var formData = new FormData()
            formData.set('id', id)

            const add = async () => await handleAddProductToWishlist(formData).then(({ success }: any) => {
                if (true === success) {
                    dispatch({ type: 'add', id })

                    /*
                    // send google events
                    googleSendEvent('add_to_wishlist', {
                        value: (lineItem.priceDefinition.price / (lineItem.payload.purchaseUnit ?? 1)) ?? null,
                        currency: 'de-CH' === locale ? 'CHF' : 'EUR',
                        items: [{ item_id: lineItem.payload.productNumber, item_name: lineItem.label, quantity: lineItem.payload.purchaseUnit ?? 1 }]
                    })
            
                    // send facebook events
                    facebookSendEvent('AddToWishlist', {
                        contentName: lineItem.label,
                        products: [{ sku: lineItem.payload.productNumber, quantity: lineItem.payload.purchaseUnit ?? 1 }]
                    })
                    */
                }
            })
            add()
        }
    }

    const removeProductFromWishlist = (id: string) => {
        if (true === session?.user?.anonym) {
            removeProductFromWishlistCookie(id)
            dispatch({ type: 'remove', id })
        } else if (false === session?.user?.anonym) {
            var formData = new FormData()
            formData.set('id', id)

            const remove = async () => await handleRemoveProductFromWishlist(formData).then(({ success }: any) => {
                if (true === success) {
                    dispatch({ type: 'remove', id })
                }
            })
            remove()
        }
    }

    const transferProductsToCustomerWishlist = () => {
        const getWishlistString = Cookies.get('swWishlist');
        const getWishlistStringArray: { id: string }[] = getWishlistString ? JSON.parse(getWishlistString) : [];
        if (Array.isArray(getWishlistStringArray)) {
            getWishlistStringArray.forEach(async ({ id }: { id: string }) => {
                try {
                    var formData = new FormData()
                    formData.set('id', id)
                    await handleAddProductToWishlist(formData)
                } catch (e: any) { console.log(e) }
            })
            dispatch({ type: 'add', items: [] })
            Cookies.set('swWishlist', JSON.stringify([]));
        }
    }

    return (
        <WishlistProviderContext.Provider value={{
            products: state,
            dispatch,

            addProduct: addProductToWishlist,
            removeProduct: removeProductFromWishlist,

            transferProductsToCustomerWishlist
        }}>
            {children}
        </WishlistProviderContext.Provider >
    )
}

export default WishlistProvider
