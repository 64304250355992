'use client';

import { type ClientPriceProps } from ".";

import { useSession } from "next-auth/react";
import formatPrice from "@utils/format-price";
import { useLocale } from "next-intl";

const ClientPrice = ({ taxId, unitPrice, purchaseUnit, referenceUnit }: ClientPriceProps) => {
    const locale = useLocale()

    const { data: session } = useSession()

    const tax = session?.user?.taxRules?.find(({ id }: { id: string }) => id === taxId)

    return (
        <>
            {formatPrice({ value: unitPrice, purchaseUnit, referenceUnit, locale, taxRate: true === session?.user?.group?.displayGross ? tax?.taxRate : undefined, currencyId: 'de-CH' === locale ? 'CHF' : 'EUR', minValue: 0.01 })}
        </>
    )
}

export default ClientPrice
